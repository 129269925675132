<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="child"
                        class="mr-2"
                    ></font-awesome-icon>
                    Students
                </h2>
                <div class="mt-3">
                    <button
                        class="sc-btn pill"
                        @click="downloadStudentList()"
                    >
                        <font-awesome-icon icon="download" class="mr-1"></font-awesome-icon>
                        Download List
                    </button>
                </div>
            </b-card>
            <b-card
                class="mt-2"
                title="Filter"
                title-tag="h5"
            >
                <b-input placeholder="Search information..." v-model="filter"></b-input>
                <b-checkbox-group
                    class="mt-3"
                    v-model="show"
                >
                    <b-check
                        value="M"
                    >
                        Male
                    </b-check>
                    <b-check
                        value="F"
                    >
                        Female
                    </b-check>
                </b-checkbox-group>
            </b-card>
        </b-skeleton-wrapper>
        <div
            class="scroll-x"
        >
            <b-table
                class="mt-2 w-100"
                :busy="loading"
                striped
                bordered
                hover
                :items="students"
                :fields="fields"
                selectable
                select-mode="single"
                @row-selected="onStudentSelected"
                :filter="filter"
                empty-text="There are no results that match your criteria."
                empty-filtered-text="There are no results that match your criteria."
            >
                <template #row-details="{ item }">    
                    {{ item }}
                </template>
                <template #cell(pos)="row">
                    {{ row.index + 1 }}
                </template>
                <template #cell(gender)="{ item }">
                    <div class="text-center">
                        <font-awesome-icon :icon="(item.gender == 'M') ? 'male' : (item.gender == 'F') ? 'female' : 'question'"></font-awesome-icon>
                        <b-button-group
                            v-if="item.gender != 'M' && item.gender != 'F'"
                            class="ml-2"
                        >
                            <b-button
                                @click="setStudentGender(item, 'M')"
                                variant="light"
                            >
                                <font-awesome-icon icon="male"></font-awesome-icon>
                            </b-button>
                            <b-button
                                @click="setStudentGender(item, 'F')"
                                variant="light"
                            >
                                <font-awesome-icon icon="female"></font-awesome-icon>
                            </b-button>
                        </b-button-group>
                    </div>
                </template>
                <template #empty-text>
                    There are no records to fetch.
                </template>
            </b-table>
        </div>
    </b-container>
</template>
<script>
import { mapState } from 'vuex';
import { downloadFile } from '@/api/downloadFile';

export default {
    data() {
        return {
            loading: false,
            selectedClass: {},
            show: [ "M", "F" ],
            filter: "",
            fields: [
                {
                    key         : "pos",
                    label       : "#",
                    sortable    : false
                },
                {
                    key         : "first_name",
                    label       : "First Name",
                    sortable    : true
                },
                {
                    key         : "last_name",
                    label       : "Last Name",
                    sortable    : true
                },
                {
                    key         : "gender",
                    label       : "Gender",
                    sortable    : true
                },
                {
                    key         : "birthdate",
                    label       : "Birthdate",
                    sortable    : true
                },
                {
                    key       : "age",
                    label     : "Age",
                    formatter : (value, key, item) => {
                        return this.$moment().diff(item.birthdate, "years");
                    },
                    sortable: true
                },
            ],
            items: []
        };
    },
    mounted() {
        if (typeof this.semester.age_intermediate_period == "number") {
            this.fields.push({
                    key       : "intermediate_age",
                    label     : "Intermediate Age",
                    formatter : (value, key, item) => {
                        return this.$moment(this.semester.first_class_date).add(this.semester.age_intermediate_period, "days").diff(item.birthdate, "years");
                    },
                    sortable: true
                });
        }
        if (this.group) {
            this.loadRecords();
        }
    },
    methods: {
        loadRecords() {
            this.loading = true;
            this.$api.get("/api/admin/fetch-students").then(({ data }) => {
                this.items   = data.records;
                this.loading = false;
            });
        },
        setStudentGender(student, gender) {
            this.$api.post(
                "/api/admin/set-student-gender",
                {
                    id     : student.id,
                    gender : gender
                }
            ).then(({ data }) => {
                if (data.success) {
                    student.gender = gender;
                }
                this.$bvToast.toast(data.message, {
                    title:   (data.success) ? "Success" : "Error",
                    variant: (data.success) ? "success" : "danger",
                    autoHideDelay: 5000,
                    appendToast: true
                });
            });
        },
        onStudentSelected(student) {
            student._details = !student._details;
        },
        showActive(row) {
            return row.is_active == true;
        },
        getAllergyInfo(student) {
            try {
                const personal_info = JSON.parse(student.personal_info);
                if (personal_info == null) {
                    return "none";
                } else {
                    return personal_info.allergy_info;
                }
            } catch (e) {
                return "Error";
            }
        },
        downloadStudentList() {
            downloadFile("/api/api/_temp_csv_file/student-list.csv?semester_id=1", "student-list.csv");
        }
    },
    computed: {
        students() {
            if(!this.items) return [];
            return this.items.filter((item) => this.show.includes(item.gender));
        },
        ...mapState(["semester", "group"])
    },
    watch: {
        group() {
            this.loadRecords();
        }
    }
  }
</script>
<style scoped>
    .scroll-x {
        max-width:  100%;
        overflow-x: scroll;
    }
</style>